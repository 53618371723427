.more-than-seventy-five {
    color: darkviolet;
}

.more-than-fifty {
    color: red;
}

.more-than-thirty {
    color: orange;
}