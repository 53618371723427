section.ant-layout {
  min-height: 100vh;
}

h1 {
  padding: 5px 0 0px;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: #ececec !important;
}

.ant-picker.ant-picker-disabled {
  background-color: #ececec !important;
}

.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: #ececec !important;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: #ececec !important;
}

.ant-table-tbody > tr > td {
  padding: 4px 8px !important;
  font-size: 13px;
  .ant-input {
    font-size: 13px;
  }
  .ant-checkbox-wrapper {
    height: 21px;
  }
}

.ant-table-pagination-right {
  text-align: right !important;
}

.ant-checkbox-wrapper-checked {
  color: #0000ff !important;
  font-weight: 600;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 13px !important;
  padding: 6px 10px !important;
  padding-left: 40px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 9px 15px !important;
  font-size: 13px !important;
}

.form-wrapper {
  background-color: #c1ebff;
  padding: 20px;
  border-radius: 5px;
  .input-wrapper {
    display: flex;
    flex-wrap: wrap;
    .input-item {
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
}

.form-wrapper-vat {
  background-color: #f6c9cc;
  padding: 20px;
  border-radius: 5px;
  .input-wrapper {
    display: flex;
    flex-wrap: wrap;
    .input-item {
      margin-right: 20px;
      margin-bottom: 8px;
    }
  }
}

// Useful css class
.text-bold {
  font-weight: bold;
}
.iv-text {
  font-weight: 500;
  color: #0000ff !important;
}
.vat-row-color {
  color: #ff9bc4;
}
.stock-low-row-color {
  color: #ff0000;
}
.new-price {
  color: #52c41a;
}
.pr-1 {
  padding-right: 10px;
}
.pr-2 {
  padding-right: 20px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-2-minus {
  margin-top: -20px;
}
.d-inline {
  display: inline-block;
}
.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.break-word {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
