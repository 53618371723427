.invoice-customer-vat-pdf-page {
  background-color: #ffffff;
  width: 793px;
  height: 560px;
  padding: 30px;
  font-family: 'Sarabun';
  .print-bill-header {
    display: flex;
    height: 40px;
    .company-logo {
      display: inline-block;
      width: 10%;
      img {
        width: 100%;
        margin-top: 5px;
      }
    }
    .company-detail {
      display: inline-block;
      margin-left: 20px;
      font-size: 10px;
      width: 60%;
      font-weight: 700;
      .company-name {
        font-size: 14px;
        font-weight: bold;
      }
      .tag-no {
        font-size: 11px;
      }
    }
    .invoice-header {
      display: inline-block;
      text-align: right;
      width: 27%;
      .invoice-tag {
        height: 35px;
        width: 100%;
        background-color: #cbedff;
        border-radius: 3px;
        text-align: center;
        padding: 5px 0;
        font-weight: 700;
        font-size: 16px;
      }
      .bill-no-detail {
        margin-top: 5px;
        text-align: left;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
  .print-bill-customer-detail {
    border-radius: 3px;
    border: 1.5px solid #b8b8b8;
    display: flex;
    height: 30px;
    margin-top: 5px;
    font-size: 12px;
    .customer-bill-detail {
      display: inline-block;
      width: 80%;
      padding-top: 3px;
      padding-left: 12px;
      padding-right: 10px;
    }
    .page-index {
      display: inline-block;
      width: 20%;
      text-align: right;
      padding-right: 5px;
      padding-top: 4px;
    }
  }
  .bill-item-list {
    display: flex;
    padding-top: 5px;
    height: 325px;
    .bill-table {
      display: inline-block;
      padding-right: 2px;
      table {
        width: 100%;
        empty-cells: show;
        table-layout: fixed;
      }
      table thead th {
        background: #d6d6d6;
        border: 1px solid #808080;
        text-align: center;
        font-size: 13px;
        height: 30px;
        padding-top: 5px;
      }
      table th {
        vertical-align: text-top;
        border-left: 1px solid #b8b8b8;
        border-right: 1px solid #b8b8b8;
      }
      table td {
        vertical-align: text-top;
        border-left: 1px solid #b8b8b8;
        border-right: 1px solid #b8b8b8;
      }
      table tbody td {
        font-size: 12px;
        padding: 2px 0 2px;
      }
      table tbody tr {
        border-bottom: 1px solid #b8b8b8;
      }
      .product-title-column {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: clip;
      }
    }
    .credit-table {
      display: inline-block;
      padding-left: 2px;
      table {
        width: 100%;
        empty-cells: show;
        table-layout: fixed;
      }
      table thead th {
        background: #d6d6d6;
        border: 1px solid #808080;
        text-align: center;
        font-size: 13px;
        height: 30px;
        padding-top: 5px;
      }
      table th {
        vertical-align: text-top;
        border-left: 1px solid #b8b8b8;
        border-right: 1px solid #b8b8b8;
      }
      table td {
        vertical-align: text-top;
        border-left: 1px solid #b8b8b8;
        border-right: 1px solid #b8b8b8;
      }
      table tbody td {
        font-size: 12px;
        padding: 2px 0 2px;
      }
      table tbody tr {
        border-bottom: 1px solid #b8b8b8;
      }
      .product-title-column {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: clip;
      }
    }
  }
  .total-summary-section {
    background-color: #ffffff;
    .total-section {
      display: flex;
      border-top: 1px solid #b8b8b8;
      border-bottom: 1px solid #b8b8b8;
      height: 65px;
      margin-top: 3px;
      .bill-remark {
        font-size: 10px;
        border-left: 1px solid #b8b8b8;
        width: 75%;
        padding-left: 5px;
        display: flex;
        .remark-label {
          display: inline-block;
          width: 10%;
        }
        .remark-content {
          display: inline-block;
          width: 90%;
          .remark-item {
            display: inline-block;
            width: 33%;
          }
        }
      }
      .discount-total-vat {
        font-size: 12px;
        font-weight: 700;
        display: flex;
        width: 25%;
        .text-total-label {
          width: 40%;
          border-left: 1px solid #b8b8b8;
          text-align: right;
          padding-right: 5px;
        }
        .number-total {
          width: 60%;
          text-align: right;
          padding-right: 10px;
          border-left: 1px solid #b8b8b8;
          border-right: 1px solid #b8b8b8;
        }
      }
    }
    .footer-section {
      display: flex;
      height: 30px;
      font-size: 10px;
      .sign-box {
        padding: 15px 5px 0px;
        width: 50%;
      }
    }
  }
}
