// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

html {
  scroll-behavior: 'smooth';
}

@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100;300&display=swap');

.only-print {
  display: none;
}

.page-break-before {
  page-break-before: always;
}

.page-break-after {
  page-break-after: always;
}

@media print {
  .only-print {
    display: block;
    padding: 5px;
  }
  .no-print {
    display: none;
  }
  .ant-layout-header {
    display: none;
  }
  .ant-layout-footer {
    display: none;
  }
  .page-break-before {
    page-break-before: always;
  }
  .page-break-after {
    page-break-after: always;
  }
  .carbon-pdf {
    font-family: "Courier";
  }
}
